import React from 'react'
import { trpc } from './trpc'
import { Button, Grid } from '@mui/material'
import styled from 'styled-components'

const StyledButton = styled(Button)`
    height: 70px;
    width: 100%;
`

export function LogActivity() {
    const utils = trpc.useContext()

    const createActivity = trpc.useMutation(['createActivity'], {
        onSuccess() {
            utils.invalidateQueries(['getActivities'])
        },
    })

    function createWickeln() {
        createActivity.mutate({
            type: 'Wickeln',
            text: 'Gewickelt',
            timestamp: new Date(),
        })
    }

    function createStillen() {
        createActivity.mutate({
            type: 'Stillen',
            text: 'Gestillt',
            timestamp: new Date(),
        })
    }

    function createSpuckenGross() {
        createActivity.mutate({
            type: 'Spucken',
            text: 'Gespuckt viel',
            timestamp: new Date(),
        })
    }

    function createSpuckenKlein() {
        createActivity.mutate({
            type: 'Spucken',
            text: 'Gespuckt wenig',
            timestamp: new Date(),
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <StyledButton onClick={createSpuckenKlein} variant="contained">
                    Spucken kl.
                </StyledButton>
            </Grid>
            <Grid item xs={6}>
                <StyledButton onClick={createStillen} variant="contained">
                    Gestillt
                </StyledButton>
            </Grid>
            <Grid item xs={6}>
                <StyledButton onClick={createSpuckenGross} variant="contained">
                    Spucken gr.
                </StyledButton>
            </Grid>
            <Grid item xs={6}>
                <StyledButton onClick={createWickeln} variant="contained">
                    Gewickelt
                </StyledButton>
            </Grid>
        </Grid>
    )
}
