import React, { useState } from 'react'
import { trpc } from './trpc'
import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import styled from 'styled-components'

const StyledBox = styled.div`
    padding: 10px;
`

export function ShowLastActivityTime() {
    const activities = trpc.useQuery(['getActivities'], {
        onSuccess(data) {
            const types = ['Wickeln', 'Stillen']
            const timesMap: { type: string; time: string }[] = []

            types.forEach((eachType) => {
                const theFirstActivity = data.find(
                    (eachActivity) => eachActivity.type === eachType
                )
                if (theFirstActivity) {
                    timesMap.push({
                        type: eachType,
                        time:
                            moment()
                                .diff(
                                    moment(theFirstActivity.timestamp),
                                    'hours',
                                    true
                                )
                                .toFixed(1) + '',
                    })
                }
            })
            setLastTimesMap(timesMap)
        },
    })

    const [lastTimesMap, setLastTimesMap] = useState<
        { type: string; time: string }[]
    >([])

    if (!activities.data) return <div>Loading...</div>
    return (
        <Grid container spacing={2}>
            {lastTimesMap.map((eachEntry) => (
                <Grid item xs={6}>
                    <StyledBox>
                        <Typography>{eachEntry.type}</Typography>
                        <Typography>
                            {'vor ' + eachEntry.time + ' Std'}
                        </Typography>
                    </StyledBox>
                </Grid>
            ))}
        </Grid>
    )
}
