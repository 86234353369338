import React, { useState } from 'react'
import { trpc } from './trpc'
import styled from 'styled-components'
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import { TimePicker } from '@mui/x-date-pickers'

const StyledList = styled(List)`
    overflow: auto;
    max-height: 70vh;
`

const StyledListItemButton = styled(ListItemButton)`
    padding-right: 0px !important;
`

const StyledDialog = styled.div`
    padding: 10px;
`

interface EntryProps {
    time: Date
    text: string
}

const Entry = ({ time, text }: EntryProps) => {
    function getTimeString(time: Date) {
        if (moment(time).isBefore(moment().startOf('day'))) {
            return moment(time).format('DD.MM. HH:mm')
        }
        return moment(time).format('HH:mm')
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={7}>
                <Typography align="left">{text}</Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography align="right">{getTimeString(time)}</Typography>
            </Grid>
        </Grid>
    )
}

export function ShowActivity() {
    const utils = trpc.useContext()
    const activities = trpc.useQuery(['getActivities'])
    const deleteActivity = trpc.useMutation(['deleteActivity'], {
        onSuccess() {
            utils.invalidateQueries(['getActivities'])
        },
    })
    const updateActivity = trpc.useMutation(['updateActivity'], {
        onSuccess() {
            utils.invalidateQueries(['getActivities'])
        },
    })
    const [filterType, setFilterType] = useState('')
    const [open, setOpen] = React.useState(false)
    const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false)
    const [timePickerOpen, setTimePickerOpen] = React.useState(false)
    const [activityId, setActivityId] = React.useState('')
    const [activityTime, setActivityTime] = React.useState<Date | null>(null)

    const handleDeleteFinish = () => {
        deleteActivity.mutate(activityId)
        setActivityId('')
        setOpen(false)
    }

    const handleUpdateFinish = () => {
        const activity = activities?.data?.find(
            (eachActivity) => eachActivity._id.toString() === activityId
        )
        if (activityTime) {
            if (activity && activityTime) {
                updateActivity.mutate({
                    id: activityId || '',
                    timestamp: new Date(activityTime.toString()),
                })
            }
        }
        setActivityId('')
        setUpdateDialogOpen(false)
        setTimePickerOpen(false)
    }

    const handleClose = () => {
        setOpen(false)
        setUpdateDialogOpen(false)
        setTimePickerOpen(false)
    }

    function onDeleteActivity(id: string) {
        setOpen(true)
        setActivityId(id)
    }

    const handleChange = (newValue: Date | null) => {
        setActivityTime(newValue)
    }

    function changeActivity(id: string, activity: { timestamp: Date }) {
        setActivityId(id)
        setActivityTime(activity.timestamp)
        setUpdateDialogOpen(true)
        setTimePickerOpen(true)
    }

    if (!activities.data) return <div>Loading...</div>
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="filterLabel">Filter</InputLabel>
                <Select
                    labelId="filterLabel"
                    value={filterType}
                    onChange={(event) => {
                        setFilterType(event.target.value as string)
                    }}
                    label="Filter"
                >
                    {['', 'Stillen', 'Wickeln', 'Spucken'].map((eachType) => (
                        <MenuItem value={eachType}>{eachType}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <StyledList>
                {activities.data
                    .filter((eachActivity) =>
                        filterType ? eachActivity.type === filterType : true
                    )
                    .map((eachActivity) => (
                        <>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() =>
                                            onDeleteActivity(
                                                eachActivity._id.toString()
                                            )
                                        }
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <StyledListItemButton
                                    onClick={() =>
                                        changeActivity(
                                            eachActivity._id.toString(),
                                            eachActivity
                                        )
                                    }
                                >
                                    <Entry
                                        time={eachActivity.timestamp}
                                        text={eachActivity.text}
                                    />
                                </StyledListItemButton>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    ))}
            </StyledList>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Aktivität wirklich löschen?'}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button
                        onClick={handleDeleteFinish}
                        autoFocus
                        color="error"
                        variant="contained"
                    >
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={updateDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <StyledDialog>
                    <TimePicker
                        label="Zeit"
                        ampm={false}
                        showToolbar={true}
                        open={timePickerOpen}
                        onClose={() => setTimePickerOpen(false)}
                        onOpen={() => setTimePickerOpen(true)}
                        value={activityTime}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </StyledDialog>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button
                        onClick={handleUpdateFinish}
                        autoFocus
                        variant="contained"
                    >
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
