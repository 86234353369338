import {
    AppBar,
    CssBaseline,
    IconButton,
    SvgIcon,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import styled from 'styled-components'
import './App.css'
import { LogActivity } from './components/LogActivity'
import { trpc } from './components/trpc'
import Box from '@mui/material/Box'
import { grey } from '@mui/material/colors'
import { StyledEngineProvider } from '@mui/material/styles'
import { ShowActivity } from './components/ShowActivities'
import superjson from 'superjson'
import { ShowLastActivityTime } from './components/ShowLastActivityTime'
import { ReactComponent as WhaleIcon } from './assets/whale.svg'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const drawerBleeding = 56

const Root = styled('div')(({ theme }) => ({
    height: '100%',
}))

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#fff',
}))

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}))

const backendURL =
    process.env.REACT_APP_BACKEND_URL || 'http://172.18.255.73:5001/trpc'

function App() {
    const [queryClient] = useState(() => new QueryClient())
    const [trpcClient] = useState(() =>
        trpc.createClient({
            url: backendURL,
            transformer: superjson,
        })
    )
    const [open, setOpen] = React.useState(true)

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen)
    }

    return (
        <div className="App">
            <CssBaseline />
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledEngineProvider injectFirst>
                            <Root>
                                <AppBar position="static">
                                    <Toolbar>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            sx={{ mr: 2 }}
                                        >
                                            <SvgIcon
                                                fontSize="large"
                                                component={WhaleIcon}
                                                inheritViewBox
                                            />
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            sx={{ flexGrow: 1 }}
                                            align="left"
                                        >
                                            FINE
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                <ShowLastActivityTime />
                                <ShowActivity />

                                {/* <Box sx={{ textAlign: 'center', pt: 1 }}>
                                <Button onClick={toggleDrawer(true)}>
                                    Open
                                </Button>
                            </Box> */}

                                <SwipeableDrawer
                                    container={window.document.body}
                                    anchor="bottom"
                                    open={open}
                                    onClose={toggleDrawer(false)}
                                    onOpen={toggleDrawer(true)}
                                    swipeAreaWidth={drawerBleeding}
                                    disableSwipeToOpen={false}
                                    ModalProps={{
                                        keepMounted: true,
                                    }}
                                >
                                    <StyledBox
                                        sx={{
                                            position: 'absolute',
                                            top: -drawerBleeding,
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            visibility: 'visible',
                                            right: 0,
                                            left: 0,
                                        }}
                                    >
                                        <Puller />
                                        <Typography
                                            sx={{
                                                p: 2,
                                                color: 'text.secondary',
                                            }}
                                        >
                                            Aktionen
                                        </Typography>
                                    </StyledBox>
                                    <StyledBox
                                        sx={{
                                            px: 2,
                                            pb: 2,
                                            height: '100%',
                                            overflow: 'auto',
                                        }}
                                    >
                                        <div>
                                            <LogActivity />
                                        </div>
                                    </StyledBox>
                                </SwipeableDrawer>
                            </Root>
                        </StyledEngineProvider>
                    </LocalizationProvider>
                </QueryClientProvider>
            </trpc.Provider>
        </div>
    )
}

export default App
